const header = {
  homepage: 'htpps://innokentii.eu',
  title: 'Intel Finland',
}

const about = {
  name: 'Innokentii Kozlov',
  role: 'Cloud Engineer',
  description:
    'I am a Cloud Engineer with a strong background in software development. I have experience in building and maintaining cloud infrastructure, CI/CD pipelines, and monitoring systems. I am passionate about learning new technologies and solving complex problems.',
  resume: 'https://innokentii.eu/CV.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/innokentii-kozlov/',
    github: 'https://github.com/Kesha123',
  },
}

const skills = [
  'Python',
  'Tornado',
  'NodeJS',
  'JavaScript',
  'TypeScript',
  'Express.js',
  'React',
  'Git',
  'Github',
  'Github Actions',
  'CI/CD',
  'Docker',
  'Kubernetes',
  'Testing',
  'Bash',
  'Linux',
  'DigitalOcean',
  'Cloud Technologies',
  'Nginx',
  'SQL',
  'SQLite',
  'PostgreSQL',
  'Dart',
  'Flutter',
  'Kotlin',
  'Android',
  'Websockets',
  'API'
];

const contact = {
  email: 'innokentiikozlov@gmail.com',
}

export { header, about, skills, contact }
